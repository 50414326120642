import React from 'react';

const Home = () => {
  return (
    <div>
      <center>
      <h1>Welcome to WAX Portal</h1>
      <p>Your gateway to the WAX Blockchain!</p>
      </center>
    </div>
  );
};

export default Home;
