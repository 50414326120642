import React, { useEffect, useState, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './Marketplace.css';

const Marketplace = ({ setLoading }) => {
    const [sales, setSales] = useState([]);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [localLoading, setLocalLoading] = useState(false); // Local loading state
    const [isMenuExpanded, setIsMenuExpanded] = useState(false); // Menu state
    const [scrollbarWidth, setScrollbarWidth] = useState(0); // Scrollbar width state
    const [currentImageIndex, setCurrentImageIndex] = useState({});
    const [transitioning, setTransitioning] = useState({}); // Transitioning state

    const location = useLocation();
    const navigate = useNavigate();

    const defaultParams = {
        state: 1,
        page: 1,
        limit: 60,
        order: 'desc',
        sort: 'created',
    };

    const getParamsFromUrl = () => {
        const searchParams = new URLSearchParams(location.search);
        return {
            state: searchParams.get('state') || defaultParams.state,
            page: searchParams.get('page') || defaultParams.page,
            limit: searchParams.get('limit') || defaultParams.limit,
            order: searchParams.get('order') || defaultParams.order,
            sort: searchParams.get('sort') || defaultParams.sort,
        };
    };

    const updateUrlParams = (params) => {
        const searchParams = new URLSearchParams(params);
        navigate(`${location.pathname}?${searchParams.toString()}`);
    };

    const fetchSalesData = async (pageNumber) => {
        setLoading(true);
        setLocalLoading(true);
        setError(null);

        const params = getParamsFromUrl();
        params.page = pageNumber;

        try {
            const response = await fetch(
                `https://wax.api.atomicassets.io/atomicmarket/v2/sales?state=${params.state}&page=${params.page}&limit=${params.limit}&order=${params.order}&sort=${params.sort}`
            );
            const data = await response.json();
            if (data && data.data) {
                if (data.data.length < params.limit) {
                    setHasMore(false);
                }
                setSales(prevSales => {
                    const updatedSales = [...prevSales, ...data.data];
                    // Ensure the number of containers is divisible into equal rows
                    const remainder = updatedSales.length % params.limit;
                    if (remainder !== 0) {
                        updatedSales.splice(-remainder, remainder);
                    }
                    return updatedSales;
                });
            } else {
                setError('No sales data available.');
            }
        } catch (err) {
            setError('Error fetching sales data.');
        } finally {
            setLoading(false);
            setLocalLoading(false);
        }
    };

    useEffect(() => {
        const params = getParamsFromUrl();
        setPage(parseInt(params.page));
        fetchSalesData(params.page);
    }, [location.search]);

    useEffect(() => {
        // Update URL with default params if not already set
        const params = getParamsFromUrl();
        if (!location.search) {
            updateUrlParams(params);
        }
    }, [location]);

    const handleScroll = useCallback(() => {
        if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || localLoading || !hasMore) return;
        setPage(prevPage => {
            const nextPage = prevPage + 1;
            const params = getParamsFromUrl();
            params.page = nextPage;
            updateUrlParams(params);
            fetchSalesData(nextPage);
            return nextPage;
        });
    }, [localLoading, hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    useEffect(() => {
        // Calculate scrollbar width and set it to state
        const calculateScrollbarWidth = () => {
            const scrollDiv = document.createElement('div');
            scrollDiv.style.width = '100px';
            scrollDiv.style.height = '100px';
            scrollDiv.style.overflow = 'scroll';
            scrollDiv.style.position = 'absolute';
            scrollDiv.style.top = '-9999px';
            document.body.appendChild(scrollDiv);
            const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            setScrollbarWidth(scrollbarWidth);
        };
        calculateScrollbarWidth();
    }, []);

    const formatMediaUrl = (url) => {
        const baseUrl = process.env.REACT_APP_RESIZER_URL || 'https://resizer.waxportal.com/resize';

        if (url) {
            return `${baseUrl}?url=${url}`;
        } else {
            return 'placeholder-image-url'; // Provide a placeholder image URL if none available
        }
    };

    const toggleMenu = () => {
        setIsMenuExpanded(!isMenuExpanded);
    };

    useEffect(() => {
        if (sales.length > 0) {
            const initialIndices = {};
            const initialTransitioning = {};
            sales.forEach(sale => {
                initialIndices[sale.sale_id] = 0;
                initialTransitioning[sale.sale_id] = false;
            });
            setCurrentImageIndex(initialIndices);
            setTransitioning(initialTransitioning);
        }
    }, [sales]);

    const handleNextImage = (saleId, totalAssets) => {
        if (currentImageIndex[saleId] >= totalAssets - 1) return;
        setTransitioning(prevState => ({ ...prevState, [saleId]: true }));
        setTimeout(() => {
            setCurrentImageIndex(prevState => {
                const newIndex = prevState[saleId] + 1;
                return { ...prevState, [saleId]: newIndex };
            });
            setTimeout(() => {
                setTransitioning(prevState => ({ ...prevState, [saleId]: false }));
            }, 300); // Duration of the transition
        }, 0);
    };

    const handlePreviousImage = (saleId, totalAssets) => {
        if (currentImageIndex[saleId] <= 0) return;
        setTransitioning(prevState => ({ ...prevState, [saleId]: true }));
        setTimeout(() => {
            setCurrentImageIndex(prevState => {
                const newIndex = prevState[saleId] - 1;
                return { ...prevState, [saleId]: newIndex };
            });
            setTimeout(() => {
                setTransitioning(prevState => ({ ...prevState, [saleId]: false }));
            }, 300); // Duration of the transition
        }, 0);
    };

    const getImageUrl = (asset) => {
        if (asset?.template) {
            return formatMediaUrl(asset.template?.immutable_data?.img || asset.template?.immutable_data?.video);
        } else {
            return 'placeholder-image-url'; // Provide a placeholder image URL if none available
        }
    };

    return (
        <div className={`content ${isMenuExpanded ? 'menu-expanded' : ''}`}>
            {error && <p>{error}</p>}
            <div
                className="sales-grid"
                style={{
                    width: `calc(100% - ${isMenuExpanded ? 250 : 0}px - ${scrollbarWidth}px - 40px)`
                }}
            >
                {sales.map(sale => {
                    const isBundle = sale.assets.length > 1;
                    const currentIndex = currentImageIndex[sale.sale_id] || 0;
                    const asset = sale.assets[currentIndex];
                    const img_url = getImageUrl(asset);
                    const name = isBundle ? `NFT Bundle (${sale.assets.length})` : (asset.template?.immutable_data?.name || asset.data.name);
                    const mint = asset.template_mint;

                    return (
                        <div className="sale-box" key={sale.sale_id}>
                            <div className="mint-number">#{mint}</div>
                            {isBundle && (
                                <div className="image-navigation">
                                    <button
                                        onClick={() => handlePreviousImage(sale.sale_id, sale.assets.length)}
                                        disabled={currentIndex === 0}
                                        className={currentIndex === 0 ? 'disabled' : ''}
                                    >
                                        ‹
                                    </button>
                                    <button
                                        onClick={() => handleNextImage(sale.sale_id, sale.assets.length)}
                                        disabled={currentImageIndex[sale.sale_id] >= sale.assets.length - 1}
                                        className={currentImageIndex[sale.sale_id] >= sale.assets.length - 1 ? 'disabled' : ''}
                                    >
                                        ›
                                    </button>
                                </div>
                            )}
                            <div className={`image-container ${transitioning[sale.sale_id] ? 'fade' : ''}`}>
                                <img src={img_url} alt="Asset Image" className="sale-img" />
                            </div>
                            <div className="sale-info-container">
                                <p className="sale-info-bold">{name}</p>
                                <p className="sale-info">{asset.collection.collection_name}</p>
                                <p className="sale-info">{(sale.listing_price / 100000000).toFixed(4)} WAX</p>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Marketplace;
