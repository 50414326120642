import React from 'react';
import logo from './assets/logo.png';
import './Header.css';

const Header = ({ onMenuToggle, onLogin, onLogout, session, loading }) => {
  let walletName = '';
  if (session && session.actor) {
    walletName = session.actor.toString(); // Get the actor's name from the session
  }

  return (
    <header className="banner">
      <div className="header-content">
        <div className="left-section">
          <div className="menu-icon" onClick={onMenuToggle}>&#9776;</div>
          <a href="https://waxportal.com">
            <img className="logo" src={logo} alt="WAX Portal Logo" />
          </a>
        </div>
        <div className="login-info">
          {session ? (
            <>
              <span className="wallet-name">{walletName}</span>
              <button className="logout-button" onClick={onLogout}>&#10005;</button>
            </>
          ) : (
            <button className="login-button" onClick={onLogin}>Login</button>
          )}
        </div>
      </div>
      <div className={`loading-bar ${loading ? 'pulsing' : ''}`}></div>
    </header>
  );
};

export default Header;
