import { SessionKit } from "@wharfkit/session";
import { WebRenderer } from "@wharfkit/web-renderer";
import { WalletPluginAnchor } from "@wharfkit/wallet-plugin-anchor";
import { WalletPluginCloudWallet } from "@wharfkit/wallet-plugin-cloudwallet";
import { WalletPluginWombat } from "@wharfkit/wallet-plugin-wombat";

const chains = [
  {
    id: process.env.REACT_APP_CHAINID,
    url: process.env.REACT_APP_RPC,
  }
];

export const sessionKit = new SessionKit({
  appName: process.env.REACT_APP_SITE_TITLE,
  chains,
  ui: new WebRenderer(),
  walletPlugins: [
    new WalletPluginAnchor(),
    new WalletPluginCloudWallet(),
    new WalletPluginWombat()
  ],
});
