import React from 'react';
import { Link } from 'react-router-dom';
import './Menu.css';

const Menu = ({ isOpen, onSelect }) => {
  return (
    <div className={`menu-content ${isOpen ? 'open' : ''}`} id="menuContent">
      <ul>
        <li onClick={onSelect}><Link to="/">Home</Link></li>
        <li onClick={onSelect}><Link to="/listing">Listing</Link></li>
        <li onClick={onSelect}><Link to="/marketplace">Marketplace</Link></li>
        <li onClick={onSelect}><Link to="/pagenav">Page Navigation</Link></li>
      </ul>
    </div>
  );
};

export default Menu;
