import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Menu from './components/Menu';
import Home from './pages/Home';
import Listing from './pages/Listing';
import Marketplace from './pages/Marketplace';
import PageNav from './pages/PageNav';
import { sessionKit } from './sessionKit';
import './App.css';

const App = () => {
  const [session, setSession] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [loading, setLoading] = useState(false); // New state for loading

  useEffect(() => {
    const autoLogin = async () => {
      try {
        const restoredSession = await sessionKit.restore();
        if (restoredSession) {
          setSession(restoredSession);
        }
      } catch (error) {
        console.error('Failed to restore session:', error);
      }
    };

    autoLogin();
  }, []);

  const handleLogin = async () => {
    try {
      const { session } = await sessionKit.login();
      if (session) {
        setSession(session);
      }
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await sessionKit.logout();
      setSession(null);
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleMenuSelect = () => {
    setIsMenuOpen(false); // Close the menu after selection
  };

  return (
    <Router>
      <Header 
        onMenuToggle={handleMenuToggle} 
        onLogin={handleLogin} 
        onLogout={handleLogout} 
        session={session} 
        loading={loading} // Pass loading state to Header
      />
      <Menu isOpen={isMenuOpen} onSelect={handleMenuSelect} />
      <div className={`content ${isMenuOpen ? 'shifted' : ''}`} style={{ paddingTop: '80px' }}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/listing" element={<Listing />} />
          <Route path="/marketplace" element={<Marketplace setLoading={setLoading} />} /> {/* Pass setLoading to Marketplace */}
          <Route path="/pagenav" element={<PageNav />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
